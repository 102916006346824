import { Route } from "react-router-dom/cjs/react-router-dom.min"
// import JobOrderAllDetail from "./ContainerAllDetail/JobOrderAllDetail"
import TruckIncomeSummary from "./TruckIncomSummary/TruckIncomeSummary"
import JobPlaning from "./JobPlaning/JobPlaning"
import JobOrderAllDetail from "./ContainerAllDetail/JobOrderAllDetail"
import DriverTripPayment from "./DriverTripPayment/DriverTripPayment"
import DriverSalary from "./DriverSalary/DriverSalary"
import DriverAdvancePay from "./DriverAdvancePay/DriverAdvancePay"

export const extraPageName = {
  JobPlaning: "การวางแผนงาน",
  JobPlaningV2: "การวางแผนงาน(V2)",
  JobOrderAllDetail: "รายละเอียดงานและคชจ.",
  TruckIncomeSummary: "รายงานรายได้แยกตามรถ",
  DriverTripPayment: "ค่าเที่ยว พนักงานขับรถ",
  DriverSalary: "เงินเดือนพนักงานขับรถ",
  DriverAdvancePay: "เงินเบิกล่วงหน้า",
}
export const extraPageMenu = [
  { page: "JobPlaning", num: "8.1", menuName: extraPageName.JobPlaning, prNum: 8 },
  { page: "JobOrderAllDetail", num: "8.2", menuName: extraPageName.JobOrderAllDetail, prNum: 8,  permission: "EXE" },
  { page: "TruckIncomeSummary", num: "8.3", menuName: extraPageName.TruckIncomeSummary, prNum: 8,  permission: "EXE;FIN" },
  { page: "DriverTripPayment", num: "8.4", menuName: extraPageName.DriverTripPayment, prNum: 8, permission: "EXE;FIN"},
  { page: "DriverAdvancePay", num: "8.5", menuName: extraPageName.DriverAdvancePay, prNum: 8},
  { page: "DriverSalary", num: "8.6", menuName: extraPageName.DriverSalary, prNum: 8, permission: "EXE;FIN"},
]

export const extraRoute = (path)=> [
  <Route key={"JobOrderAllDetail"} path={`${path}/JobOrderAllDetail`} ><JobOrderAllDetail /> </Route>,
  <Route key={"JobPlaning"} path={`${path}/JobPlaning`} ><JobPlaning /> </Route>,
  <Route key={"TruckIncomeSummary"} path={`${path}/TruckIncomeSummary`} ><TruckIncomeSummary /> </Route>,
  <Route key={"DriverTripPayment"} path={`${path}/DriverTripPayment`} ><DriverTripPayment /> </Route>,
  <Route key={"DriverAdvancePay"} path={`${path}/DriverAdvancePay`} ><DriverAdvancePay /> </Route>,
  <Route key={"DriverSalary"} path={`${path}/DriverSalary`} ><DriverSalary /> </Route>,
]

export const extraMainRoute = (path)=> []
