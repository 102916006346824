
import { Box, TextField } from '@mui/material';
import { BoxFC, BoxFR } from 'components/BoxCustom';
import DataGridCellExpand from 'components/DataGridCellExpand/DataGridCellExpand';
import React, { useCallback, useState, useContext, useEffect, useMemo } from 'react';
import { GlobalStateContext } from 'contexts/GlobalStateContext';
import DateTimeSelect from 'components/DateTimeSelect';
import SearchButton from 'components/buttons/SearchButton';
import ClearButton from 'components/buttons/ClearButton';
import { initFilterData, clearFilterData } from './initData';
import { branchApiNm } from 'branch/constant/branchApiNm';
import { driverTripPaymentColumns, driverTripPaymentEndColumns } from './columns';
import { green, red } from '@mui/material/colors';
import ComboBox from 'components/ComboBox';
import { alertError } from 'components/Alert';
import SelectMonthDialog from './SelectMonthDialog';
import { DesktopDatePicker } from '@mui/x-date-pickers-pro';
import { numberFormat } from 'utils';
import DailyJobDialogV3 from 'pages/Menu1/DailyJob/DailyJobDialogV3';
import dayjs from 'dayjs';

// let selectedId = null;
let lastFilter = null
let jobOrdId = null
let selectedIds = []
let selectedSalDte = null

const watchCols = [87, 7]
// const watchFields = watchCols.map(id => `E${id}`)

const DriverTripPayment = () => {
  const { ax, msData } = useContext(GlobalStateContext)
  const [dataTable, setDataTable] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogSelectMonth, setDialogSelectMonthOpen] = useState(false)
  const [filterData, setFilterData] = useState({ ...initFilterData })
  const [selectionModel, setSelectionModel] = useState([])

  const textFieldProps = useCallback((name) => ({
    size: "small",
    sx: { width: 120 },
    value: filterData[name] || "",
    onChange: (e) => { setFilterData(o => ({ ...o, [name]: e.target.value || null })) }
  }), [filterData])

  const monthPickerProp = useMemo(() => ({
    disableMaskedInput: true,
    label: "คิดเงินเดือน",
    inputFormat: "DD/MM/YYYY",
    value: filterData.SalDte,
    onChange: (newValue) => { setFilterData(o => ({ ...o, SalDte: newValue })) },
    renderInput: (params) => <TextField size="small" {...params} sx={{ width: 160 }} />,

  }), [filterData.SalDte])

  const setAllData = useCallback((datas) => {
    const newData = []
    let no = 0;
    for (const data of datas) {
      no++;
      const result = {
        id: data.id,
        No: no,
        ...data
      }
      let othAmnt = 0;
      let othDscp = new Set()
      let expTotal = 0;
      if (data.Exps) {
        for (const exp of data.Exps) {
          expTotal += +exp.Amnt
          if (watchCols.includes(exp.ExpId)) {
            result[`E${exp.ExpId}`] = +exp.Amnt
          } else {
            othAmnt += +exp.Amnt
            othDscp.add(exp.ExpNm)
          }
        }
        if (othAmnt) {
          // console.log("othDscp::", othDscp)  
          result.OthAmnt = othAmnt
          result.OthDscp = [...othDscp].join(', ')
        }
      }
      result.ExpTotal = expTotal
      newData.push(result)
    }
    setDataTable(newData)
  }, [])

  const getData = useCallback((filter) => {
    ax.post(branchApiNm.getDriverTripPayment, filter).then(value => {
      if (value.data) {
        // setDataTable(addIdForDataGrid(value.data, 'PCTrnsId'))
        lastFilter = filter
        setAllData(value.data)
      }
    })
  }, [ax, setAllData])



  const handleCellClick = useCallback((params) => {
    // selectedId = params.id
    if (params.field === 'ShowDetail') {
      jobOrdId = params.row.JobOrdId
      setDialogOpen(true)
      // } else if (params.field === 'Print') {
      //   ax.post(branchApiNm.getPrintData, { PCTrnsId: selectedId }).then(value => {
      //     if (value.data && value.data) {
      //       printJobOrderExpense({ joData: value.data, msData })
      //     }
      //   })
    } else if (params.field === 'SalDte') {
      selectedSalDte = params.value
      if (selectionModel.includes(params.id)) {

        let allOK = true
        for (const id of selectionModel) {
          if (id.split("-")[1] === "X") {
            allOK = false
            break
          }
        }

        if (!allOK) {
          alertError("มีบางข้อมูลยังไม่ลงค่าเที่ยว ไม่สามารถลงคิดเงินเดือนได้")
          return
        }

        selectedIds = dataTable.filter(r => selectionModel.includes(r.id)).map(r=>r.Exps.map(exp=>exp.PCTrnsId)).flat()
        console.log("selectedIds::", selectedIds)
      } else {
        const foundRow = dataTable.find(r => r.id === params.id)
        if (foundRow.id.split("-")[1] === "X") {
          alertError("มีบางข้อมูลยังไม่ลงค่าเที่ยว ไม่สามารถลงคิดเงินเดือนได้")
          return
        }
        selectedIds = foundRow.Exps.map(exp=>exp.PCTrnsId)
      }



      setDialogSelectMonthOpen(true)


    }
  }, [selectionModel, dataTable])

  // const handleRowDoubleClick = useCallback((params) => {
  //   selectedId = params.id
  //   setDialogOpen(true)
  // }, [])

  const onFinish = useCallback((data) => {
    getData(lastFilter)
  }, [getData])


  const onSelectMonthDialogFinish = useCallback((data) => {
    const postData = {
      PCTrnsIds: selectedIds,
      SalDte: data,
      getArgs: lastFilter
    }
    ax.post(branchApiNm.insertDeleteSalaryPCTrnsaction, postData).then(value => {
      if (value.data) {
        setAllData(value.data)
      }
    })
  }, [ax, setAllData])

  const handleCellEditCommit = useCallback((params) => {
    const expId = +params.field.substring(1)

    if(params.value === ""){
      let pcTrnsIds = []
      if (selectionModel.includes(params.id)) {
        const filtered = dataTable.filter(r => selectionModel.includes(r.id))
        for (const row of filtered) {
          const foundExp = row.Exps.find(exp => exp.ExpId === expId)
          if (foundExp) {
            pcTrnsIds.push(foundExp.PCTrnsId)
          }
        }
      }
      else {
        const row = dataTable.find(r => r.id === params.id)
        const foundExp = row.Exps.find(exp => exp.ExpId === expId)
        if (foundExp) {
          pcTrnsIds.push(foundExp.PCTrnsId)
        }
      }
      ax.post(branchApiNm.deleteDriverPayment, { PCTrnsIds: pcTrnsIds, getArgs: lastFilter }).then(value => {
        if(value.data){
          setAllData(value.data)
        }
      })
    } else {
      if (selectionModel.includes(params.id)) {
        const updateArgsArr = []
        const insertArgsArr = []
  
        for (const id of selectionModel) {
  
          const row = dataTable.find(r => r.id === id)
          const foundExp = row.Exps.find(exp => exp.ExpId === expId)
          if (foundExp) {
            updateArgsArr.push({
              PCTrnsId: foundExp.PCTrnsId,
              PCItmId: foundExp.PCItmId,
              Amnt: params.value,
            })
          } else {
            insertArgsArr.push({
              ExpId: expId,
              DrvId: row.DrvId,
              JobOrdId: row.JobOrdId,
              Amnt: params.value ,
              SalDte: row.SalDte ? dayjs(row.SalDte).format("YYYY-MM-DD") : null
            })
          }
        }
        const postData = {
          updateArgsArr,
          insertArgsArr,
          getArgs: lastFilter
        }
        ax.post(branchApiNm.insertUpdateDriverPayments, postData).then(value => {
          if (value.data) {
            setAllData(value.data)
          }
        })
      } else {
        const row = dataTable.find(r => r.id === params.id)
        const foundExp = row.Exps.find(exp => exp.ExpId === expId)
        if (foundExp) {
          const postData = {
            PCTrnsId: foundExp.PCTrnsId,
            PCItmId: foundExp.PCItmId,
            Amnt: params.value,
            getArgs: lastFilter
          }
          ax.post(branchApiNm.updateDriverPaymentAmnt, postData, false).then(value => {
            if (value.data) {
              setAllData(value.data)
            }
          })
        } else {
          const postdata = {
            ExpId: expId,
            DrvId: row.DrvId,
            JobOrdId: row.JobOrdId,
            Amnt: params.value,
            SalDte: row.SalDte ? dayjs(row.SalDte).format("YYYY-MM-DD") : null,
            getArgs: lastFilter
          }
          ax.post(branchApiNm.insertDriverPayment, postdata, false).then(value => {
            if (value.data) {
              setAllData(value.data)
            }
          })
        }
      }
    }

  }, [dataTable, ax, selectionModel, setAllData])

  const driverTripPaymentColumnsMemo = useMemo(() => {
    const newCols = []
    for (const id of watchCols) {
      newCols.push({
        field: `E${id}`,
        headerName: msData.expObj[id]?.ExpNm,
        width: 100,
        align: 'right',
        editable: true,
        valueFormatter: ({ value }) => value === null || value === undefined ?  '-': numberFormat(value) 
      })
    }
    return [
      ...driverTripPaymentColumns,
      ...newCols,
      ...driverTripPaymentEndColumns
    ]
  }, [msData.expObj])
  useEffect(() => {
    getData({ ...initFilterData })
  }, [getData])

  return (
    <BoxFC height='100%'>
      <BoxFR>
        {/* <Button variant="contained" onClick={handleSetSalDte}>กำหนดเดือนคิดค่าเที่ยว</Button> */}

        {/* <Button variant="contained" onClick={() => {} }>รายการเงินเบิกล่วงหน้า</Button> */}
        <Box flex={1} />

        <TextField {...textFieldProps('JobOrdId')} label="เลขที่ใบงาน" />
        <TextField {...textFieldProps('ContNo')} label="หมายเลขตู้" />
        <ComboBox sx={{ width: 150 }} options={msData.jobCombo} label="งาน"
          selectedId={filterData.JobId}
          setSelectedId={(id) => setFilterData(o => ({ ...o, JobId: id }))}
        />
        <ComboBox sx={{ width: 150 }} options={msData.driverOnlyCombo} label={"พนักงานขับรถ"}
          selectedId={filterData.DrvId}
          setSelectedId={(id) => setFilterData(o => ({ ...o, DrvId: id }))}
        />
        <DateTimeSelect sx={{ width: 180 }} label='วันนัด' start={filterData.AptTmSt} end={filterData.AptTmEn}
          setSelectDate={(st, en) => { setFilterData(o => ({ ...o, AptTmSt: st, AptTmEn: en })) }}
          getData={() => { getData(filterData) }}
        />
        <DateTimeSelect sx={{ width: 180 }} label='วันที่ลงจบ' start={filterData.FinDteSt} end={filterData.FinDteEn}
          setSelectDate={(st, en) => { setFilterData(o => ({ ...o, FinDteSt: st, FinDteEn: en })) }}
          getData={() => { getData(filterData) }}
        />

        <DesktopDatePicker {...monthPickerProp} />

        <SearchButton onClick={(() => getData(filterData))} />
        <ClearButton onClick={(() => setFilterData({ ...clearFilterData }))} />
      </BoxFR>
      <Box height='100%' sx={{
        "& .bg-red": { bgcolor: red[100] },
        "& .bg-green": { bgcolor: green[100] }
      }}>
        <DataGridCellExpand
          hideFooter
          checkboxSelection
          selectionModel={selectionModel}
          onSelectionModelChange={(ids) => {
            setSelectionModel(ids);
          }}
          rows={dataTable}
          columns={driverTripPaymentColumnsMemo}
          onCellEditCommit={handleCellEditCommit}
          onCellClick={handleCellClick}
        // initialState={{
        //   pinnedColumns: {
        //     left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'ShowDetail', "IsCmpt", "Print", "No", "AccNm", "AptTm", "JobOrdId", "Loc", "Amnt", "AddAmnt", "TotalOut", "ExpTotal", "Total", "OverAmnt"],
        //   },
        // }}
        />
      </Box>
      <DailyJobDialogV3
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        selectedId={jobOrdId}
        onFinish={onFinish} />
      <SelectMonthDialog
        oldData={selectedSalDte}
        dialogOpen={dialogSelectMonth}
        setDialogOpen={setDialogSelectMonthOpen}
        onFinish={onSelectMonthDialogFinish} />
    </BoxFC>
  );
}

export default DriverTripPayment