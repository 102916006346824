import { ArticleRounded } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { driverColNm } from "constants/driverConstant"
import dayjs from "dayjs";
import { pettyCashColNm } from "pages/Menu1/PettyCash/constant";
import { numberFormat } from "utils";
import { salaryDriverColNm } from "../DriverSalary/constant";

export const columns = [
  {
    field: 'ShowDetail',
    headerName: '',
    width: 40,
    align: 'center',
    renderCell: ({id}) => {
      return  id===0? "": (
        <IconButton size="small" >
          <ArticleRounded color="primary" />
        </IconButton>
      )
    }
  },
  {
    field: "No",
    headerName: "No.",
    width: 50,
    align: 'center',
  },
  {
    field: "DrvNm",
    headerName: driverColNm.DrvNm,
    width: 180,
    valueGetter: ({id, value, row: {FName, LName}}) => id===0? value: `${FName} ${LName}`
  },
  {
    field: "PCItmTm",
    headerName: pettyCashColNm.PCItmTm,
    width: 120,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM/YYYY HH:mm") : "-";
    },
  },
  {
    field: "Dscp",
    headerName: pettyCashColNm.Dscp,
    width: 180,
  },
  {
    field: "Amnt",
    headerName: pettyCashColNm.Amnt,
    width: 120,
    type: "number",
    align: 'right',
    valueFormatter: ({value}) =>  numberFormat(+value)
  },
  {
    field: "SalDte",
    headerName: salaryDriverColNm.SalDte,
    width: 100,
    // valueFormatter: ({ value }) => value ? dayjs(value).format("MM/YYYY") : '',
    renderCell: ({id, value }) => id===0? null: (
      <Button size="small" variant={value ? "outlined" : "contained"}>
        {value ? dayjs(value).format("MM/YYYY") : "กำหนดเดือน"}
      </Button>
    )
  },
  {
    field: "Rmk",
    headerName: pettyCashColNm.Rmk,
    flex: 1,
    minWidth: 120,
  },
]