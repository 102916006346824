export const salaryDriverColNm = {
  TripAmnt: "ค่าเที่ยว",
  SalAmnt: "เงินเดือน",
  IncAmnt1: "เบี้ยขยัน",
  IncAmnt2: "ค่าดูแลรถ",
  IncAmnt3: "ค่า Performance",
  IncAmnt4: "รายได้4",
  IncAmnt5: "รายได้5",
  IncAmnt6: "รายได้6",
  IncAmnt7: "รายได้7",
  OthAmnt: "รายได้อื่นๆ",
  OthDscp: "รายละเอียดรายได้อื่นๆ",
  DeSSPrct: "ปกส.(%)",
  DeSSAmnt: "หัก ปกส.",
  DeSecDepAmnt: "หักค่าประกันรถ",
  DeTfcFineAmnt: "หักค่าปรับ",
  DeAmnt1: "หัก1",
  DeAmnt2: "หัก2",
  DeOthAmnt: "หักอื่นๆ",
  DeOthDscp: "รายละเอียดหักอื่นๆ",
  TotalInc: "รวมรายได้",
  TotalDedc: "รวมรายการหัก",
  LeftAmnt: "เงินขาด-เกิน",
  GrndTotal: "คงเหลือ",
  SalDte: "รอบคิดเงินเดือน",
  DrvAdvPay: "เงินเบิกล่วงหน้า",
}