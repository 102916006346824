import { numberFormat } from "utils";
import { salaryDriverColNm } from "./constant";
import { IconButton } from "@mui/material";
import { ArticleRounded } from "@mui/icons-material";
import dayjs from "dayjs";

export const columns = [
  {
    field: 'ShowDetail',
    headerName: '',
    width: 40,
    align: 'center',
    renderCell: (params) => {
      return (
        <IconButton size="small" >
          <ArticleRounded color="primary" />
        </IconButton>
      )
    }
  },
  {
    field: "No",
    headerName: "No.",
    width: 50,
    align: 'center',
  },
  {
    field: "DrvCd",
    headerName: "รหัสพนักงาน",
    width: 90,
  },
  {
    field: "DrvNm",
    headerName: "ชื่อ-นามสกุล",
    width: 180,
  },
  {
    field: "TripAllAmnt",
    headerName: salaryDriverColNm.TripAmnt,
    width: 100,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "SalAmnt",
    headerName: salaryDriverColNm.SalAmnt,
    width: 100,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "IncAmnt1",
    headerName: salaryDriverColNm.IncAmnt1,
    width: 100,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "IncAmnt2",
    headerName: salaryDriverColNm.IncAmnt2,
    width: 100,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "IncAmnt3",
    headerName: salaryDriverColNm.IncAmnt3,
    width: 100,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "OthAmnt",
    headerName: salaryDriverColNm.OthAmnt,
    width: 100,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "OthDscp",
    headerName: salaryDriverColNm.OthDscp,
    width: 100,
  },
  {
    field: "TotalInc",
    headerName: salaryDriverColNm.TotalInc,
    width: 100,
    type: "number",
    align: "right",
    valueGetter: ({ row: { SalAmnt, IncAmnt1, IncAmnt2, IncAmnt3, IncAmnt4, OthAmnt, TripAllAmnt } }) =>
      +(SalAmnt || 0) + +(IncAmnt1 || 0) + +(IncAmnt2 || 0) + +(IncAmnt3 || 0) + +(IncAmnt4 || 0) 
      + +(OthAmnt || 0) + +(TripAllAmnt || 0),
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "DrvAdvPay",
    headerName: salaryDriverColNm.DrvAdvPay,
    width: 100,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? params.value : "-";
    },
  },
  {
    field: "DeSSPrct",
    headerName: salaryDriverColNm.DeSSPrct,
    width: 100,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? params.value : "-";
    },
  },
  {
    field: "DeSSAmnt",
    headerName: salaryDriverColNm.DeSSAmnt,
    width: 100,
    type: "number",
    align: "right",
    valueGetter: ({ value }) => Math.round(value),
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "DeSecDepAmnt",
    headerName: salaryDriverColNm.DeSecDepAmnt,
    width: 100,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "DeTfcFineAmnt",
    headerName: salaryDriverColNm.DeTfcFineAmnt,
    width: 100,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "DeAmnt1",
    headerName: salaryDriverColNm.DeAmnt1,
    width: 100,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "DeAmnt2",
    headerName: salaryDriverColNm.DeAmnt2,
    width: 100,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "DeOthAmnt",
    headerName: salaryDriverColNm.DeOthAmnt,
    width: 100,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "DeOthDscp",
    headerName: salaryDriverColNm.DeOthDscp,
    width: 150,
  },
  {
    field: "TotalDeduc",
    headerName: salaryDriverColNm.TotalDedc,
    width: 100,
    type: "number",
    align: "right",
    valueGetter: ({ row: { DrvAdvPay, DeSecDepAmnt, DeSSAmnt, DeOthAmnt, DeTfcFineAmnt, DeAmnt1, DeAmnt2 } }) => 
      +(DrvAdvPay || 0) + +(DeSecDepAmnt || 0) + +(DeSSAmnt || 0) + +(DeOthAmnt || 0) + +(DeTfcFineAmnt || 0) + +(DeAmnt1 || 0) + +(DeAmnt2 || 0),
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "GrndTotal",
    headerName: salaryDriverColNm.GrndTotal,
    width: 100,
    type: "number",
    align: "right",
    valueGetter: ({ row: { TripAllAmnt, SalAmnt, IncAmnt1, IncAmnt2, IncAmnt3, IncAmnt4, OthAmnt
      , DrvAdvPay, DeSecDepAmnt, DeSSAmnt, DeOthAmnt, DeTfcFineAmnt, DeAmnt1, DeAmnt2 } }) => + (TripAllAmnt || 0)
      + +(SalAmnt || 0) + +(IncAmnt1 || 0) + +(IncAmnt2 || 0) + +(IncAmnt3 || 0) + +(IncAmnt4 || 0) + +(OthAmnt || 0)
      - (+(DrvAdvPay) + +(DeSecDepAmnt || 0) + +(DeSSAmnt || 0) + +(DeOthAmnt || 0) + +(DeTfcFineAmnt || 0) 
        + +(DeAmnt1 || 0) + +(DeAmnt2 || 0)),
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "Rmk",
    headerName: "หมายเหตุ",
    flex: 1,
    minWidth: 120,
  },
]

export const dirverSalaryMasterColumns = [
  {
    field: "No",
    headerName: "No.",
    width: 50,
    align: 'center',
  },
  {
    field: "DrvCd",
    headerName: "รหัส พขร.",
    width: 80,
  },
  {
    field: "DrvNm",
    headerName: "พนักงานขับรถ",
    width: 220,
  },
  {
    field: "SalAmnt",
    headerName: salaryDriverColNm.SalAmnt,
    width: 110,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "MobAmnt",
    headerName: salaryDriverColNm.MobAmnt,
    width: 100,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "HousAmnt",
    headerName: salaryDriverColNm.HousAmnt,
    width: 110,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "LivAmnt",
    headerName: salaryDriverColNm.LivAmnt,
    width: 125,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "DeSSPrct",
    headerName: salaryDriverColNm.DeSSPrct,
    width: 125,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? params.value : "-";
    },
  },
  {
    field: "DeLoanAmnt",
    headerName: salaryDriverColNm.DeLoanAmnt,
    width: 125,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "DeElecAmnt",
    headerName: salaryDriverColNm.DeElecAmnt,
    width: 125,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "DeSecDepAmnt",
    headerName: salaryDriverColNm.DeSecDepAmnt,
    width: 125,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "DeEduLoanAmnt",
    headerName: salaryDriverColNm.DeEduLoanAmnt,
    width: 125,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? params.value : "-";
    },
  },
]



export const salaryDriverDetailColumns = [
  {
    field: 'ShowDetail',
    headerName: '',
    width: 60,
    align: 'center',
    renderCell: ({id}) => {
      return id === 0? "": (
        <IconButton size="small" >
          <ArticleRounded color="primary" />
        </IconButton>
      )
    }
  },
  {
    field: "No",
    headerName: "No.",
    width: 50,
    align: 'center',
  },
  {
    field: "JobOrdId",
    headerName: "เลขที่ใบงาน",
    width: 80,
  },
  {
    field: "LicNm",
    headerName: "ทะเบียนรถ",
    width: 80,
  },
  {
    field: "AptTm",
    headerName: "วันที่นัด",
    width: 100,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },
  {
    field: "ContNo",
    headerName: "หมายเลขตู้",
    width: 120,
  },
  {
    field: "Loc",
    headerName: "สถานที่",
    minWidth: 220,
    valueGetter: ({id, row: {TakePlc, Loc, RtnPlc}}) =>id===0?"": `${TakePlc}-${Loc}-${RtnPlc}`,
    flex: 1,
  },
]

export const salaryDriverDetailColumnsEnd = [
  
  {
    field: "OthAmnt",
    headerName: "ค่าอื่นๆ",
    width: 100,
    align: 'right',
    valueFormatter: ({ value }) => value ? numberFormat(value) : '-'
  },
  {
    field: "OthDscp",
    headerName: "รายละเอียดค่าอื่นๆ",
    minWidth: 150,
    flex: 1
  },
  {
    field: "ExpTotal",
    headerName: "รวม",
    width: 100,
    align: 'right',
    valueFormatter: ({ value }) => value ? numberFormat(value) : '0.00',
  },
]