import dayjs from "dayjs"

export const clearFilterData = {
  SalDte: null,
  DrvId: null
}

export const initFilterData = {
  ...clearFilterData,
  SalDte: dayjs().date()>16? dayjs().add(1, "month").format("YYYY-MM-01"): dayjs().format("YYYY-MM-16")
}

export const initDriverSalaryMasterFilterData = {
  DrvNm: null,
  IsActv: 1
}

export const initSalaryDialogDetail = {
  SalAmnt: 0,
  SalDte: dayjs().format("YYYY-MM-DD"),
  BonsAmnt:0,
  HousAmnt: 0,
  LivAmnt: 0,
  MobAmnt: 0,
  OthAmnt: 0,
  OthDscp: '',
  Rmk: '',
  DeElecAmnt: 0,
  DeEduLoanAmnt: 0,
  DeTfcFineAmnt: 0,
  DeLoanAmnt: 0,
  DeOthAmnt: 0,
  DeSecDepAmnt: 0,
  DeSSAmnt: 0,
  DeSSPrct: 0,
  DeOthDscp: '',
  SumTrip: 0,
}