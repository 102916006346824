import { ArticleRounded, EditRounded, RemoveCircle, SwapHoriz } from "@mui/icons-material"
import { IconButton, Typography } from "@mui/material"
import { pettyCashColNm } from "./constant"
import { numberFormat } from "utils"
import dayjs from "dayjs"
import { GridActionsCellItem } from "@mui/x-data-grid"
import { BoxFR } from "components/BoxCustom"
import { expenseColNm } from "constants/expenseConstant"
import { jobOrderColNm } from "constants/jobOrderConstant"

export const pettyCashColumns = [
  {
    field: 'ShowDetail',
    headerName: '',
    width: 40,
    align: 'center',
    renderCell: ({ id }) => {
      return id === 0 ? null : (
        <IconButton size="small" >
          <ArticleRounded color="primary" />
        </IconButton>
      )
    }
  },
  {
    field: "No",
    headerName: "No.",
    width: 50,
    align: "center"
  },
  {
    field: "UsrNNm",
    headerName: pettyCashColNm.UsrNNm,
    width: 100,
  },
  {
    field: "PayTm",
    headerName: pettyCashColNm.PayTm,
    width: 110,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },
  {
    field: "ExpNm",
    headerName: pettyCashColNm.ExpNm,
    width: 180,
    valueGetter: ({ row, value }) => !value ? "-" : `${row.PrPCTrnsId ? row.InOutTyp === "I" ? "(เงินทอน)" : "(เงินเพิ่ม)" : ""}${row.ExpNm}`
  },
  {
    field: "Dscp",
    headerName: pettyCashColNm.Dscp,
    width: 120,
  },
  {
    field: "JobOrdId",
    headerName: pettyCashColNm.JobOrdId,
    width: 100,
  },
  {
    field: "JobNo",
    headerName: jobOrderColNm.JobNo,
    width: 100,
  },
  {
    field: "ContNo",
    headerName: jobOrderColNm.ContNo,
    width: 120,
  },
  {
    field: "Loc",
    headerName: "สถานที่",
    valueGetter: ({ row }) => row.TakePlc || row.Loc || row.RtnPlc ? `${row.TakePlc}-${row.Loc}-${row.RtnPlc}` : "",
    width: 200,
  },
  {
    field: "PCJnlNm",
    headerName: pettyCashColNm.PCJnlNm,
    width: 100,
  },
  // {
  //   field: "Amnt",
  //   headerName: pettyCashColNm.Amnt,
  //   width: 90,
  //   align: "right",
  // },
  {
    field: "WhtPrct",
    headerName: pettyCashColNm.WhtPrct,
    width: 90,
    valueGetter: ({ id, value }) => id === 0 ? "" : `${value || 0}%`,
    align: "right",
  },
  {
    field: "AmntOut",
    headerName: "เงินจ่าย",
    width: 100,
    align: "right",
    type: "number",
    valueFormatter: (params) => params.value && +params.value > 0 ? numberFormat(params.value) : "",
  },
  {
    field: "AmntIn",
    headerName: "เงินรับ",
    width: 100,
    align: "right",
    type: "number",
    valueFormatter: (params) => params.value && +params.value > 0 ? numberFormat(params.value) : "",
  },
  {
    field: "AdmNNm",
    headerName: pettyCashColNm.AdmNNm,
    width: 100,
  },
  {
    field: "PrIsCmpt",
    headerName: pettyCashColNm.IsCmpt,
    width: 50,
    align: "center",
    valueFormatter: (params) => params.value ? <Typography color="success">✔</Typography> : "",
    // renderCell: ({value})=> value===1? <CheckCircleRounded color="success"/>: ""
  },
  {
    field: "PCPrdId",
    headerName: "ปิดยอดแล้ว",
    width: 50,
    align: "center",
    valueFormatter: (params) => params.value ? <Typography color="success">✔</Typography> : "",
    // renderCell: ({value})=> value===1? <CheckCircleRounded color="success"/>: ""
  },
  {
    field: "Rmk",
    headerName: "หมายเหตุ",
    flex: 1,
    minWidth: 120,
  },
]

const pcTransColumns = [...pettyCashColumns]
pcTransColumns.splice(0, 1,
  {
    field: 'ShowDetail',
    headerName: '',
    width: 40,
    align: 'center',
    renderCell: (params) => {
      return (
        <IconButton size="small" >
          <EditRounded color={params.row.PrIsCmpt ? "disabled" : "primary"} />
        </IconButton>
      )
    }
  })
export { pcTransColumns }

export const pcItemColumns = [

  {
    field: 'ShowDetail',
    headerName: '',
    width: 40,
    align: 'center',
    renderCell: (params) => {
      return (
        <IconButton size="small" >
          <EditRounded color={params.row.PrIsCmpt ? "disabled" : "primary"} />
        </IconButton>
      )
    }
  },
  {
    field: "No",
    headerName: "No.",
    width: 50,
    align: "center"
  },
  {
    field: "PCItmTm",
    headerName: pettyCashColNm.ItmDte,
    width: 110,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },
  {
    field: "IsCost",
    headerName: "รายได้/ต้นทุน",
    width: 100,
    valueFormatter: ({ value }) => value === 1 ? "ต้นทุน" : value === 0 ? "รายได้" : "-",
    cellClassName: ({ value }) => value === 1 ? "cost" : value === 0 ? "income" : "xxx"
  },
  {
    field: "ExpNm",
    headerName: pettyCashColNm.ExpNm,
    width: 150,
    valueGetter: (params) => params.value || "-"
  },
  {
    field: "Dscp",
    headerName: pettyCashColNm.Dscp,
    width: 100,
  },
  {
    field: "DocNo",
    headerName: pettyCashColNm.DocNo,
    width: 100,
  },
  {
    field: "DocDte",
    headerName: pettyCashColNm.DocDte,
    width: 110,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM/YYYY") : "-";
    },
  },
  {
    field: "JobOrdId",
    headerName: pettyCashColNm.JobOrdId,
    width: 90,
  },
  {
    field: "Loc",
    headerName: pettyCashColNm.Loc,
    width: 150,
  },
  {
    field: "AdmNNm",
    headerName: pettyCashColNm.AdmNNm,
    width: 80,
  },
  {
    field: "Amnt",
    headerName: pettyCashColNm.Amnt,
    width: 90,
    align: "right",
  },
  {
    field: "WhtPrct",
    headerName: pettyCashColNm.WhtPrct,
    width: 90,
    valueGetter: ({ value }) => `${value || 0}%`,
    align: "right",
  },
  {
    field: "PayAmnt",
    headerName: pettyCashColNm.PayAmnt,
    width: 90,
    align: "right",
  },
  {
    field: "Rmk",
    headerName: "หมายเหตุ",
    flex: 1,
    minWidth: 100,
  },
]

export const pcItemAddTableColumns = (handleChangeIsCost, handleRemoveRow) => [
  {
    field: "No",
    headerName: "No.",
    width: 40,
    align: "center"
  },
  {
    field: "IsCost",
    headerName: expenseColNm.IsCost,
    width: 100,
    cellClassName: ({ value }) => value === 1 ? "cost" : value === 0 ? "income" : "",
    renderCell: ({ id, value }) => id === 0 ? null : (
      <BoxFR>
        <Typography variant="body2">{value === 1 ? "ต้นทุน" : value === 0 ? "รายได้" : "-"}</Typography>
        <IconButton color="primary" onClick={() => handleChangeIsCost(id, "IsCost")}>
          <SwapHoriz />
        </IconButton>
      </BoxFR>
    )
  },
  {
    field: "IsOwnRcpt",
    headerName: expenseColNm.IsOwnRcpt,
    width: 100,
    renderCell: ({ id, value, row }) => id === 0 ? null : (
      <BoxFR>
        <Typography variant="body2">{row.IsCost === 1 ? "-"
          : value === 1 ? "บริษัท" : value === 0 ? "ลูกค้า" : "-"}</Typography>
        {
          row.IsCost === 1 ? null :
            <IconButton color="primary" onClick={() => handleChangeIsCost(id, "IsOwnRcpt")}>
              <SwapHoriz />
            </IconButton>
        }
      </BoxFR>
    )
  },
  {
    field: "ExpNm",
    headerName: pettyCashColNm.ExpNm,
    width: 150,
    valueGetter: (params) => params.value || "-"
  },
  {
    field: "Dscp",
    headerName: pettyCashColNm.Dscp,
    flex: 1,
    minWidth: 100,
    editable: true,
  },
  {
    field: "DocNo",
    headerName: pettyCashColNm.DocNo,
    width: 100,
    editable: true,
  },
  {
    field: "DocDte",
    headerName: pettyCashColNm.DocDte,
    width: 110,
    type: "date",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM/YYYY") : "-";
    },
    editable: true,
  },

  {
    field: "AmntB4Vat",
    type: "number",
    headerName: pettyCashColNm.AmntB4Vat,
    Amnt: "จำนวนเงิน",
    width: 90,
    align: "right",
    editable: true,
    valueFormatter: ({ value }) => value ? numberFormat(value) : "0.00"
  },
  {
    field: "VatPrct",
    type: "number",
    headerName: pettyCashColNm.VatPrct,
    width: 60,
    valueGetter: ({ value, id }) => id === 0 ? "" : `${value || 0}%`,
    align: "right",
    editable: true,
  },
  {
    field: "Amnt",
    type: "number",
    headerName: pettyCashColNm.Amnt,
    width: 90,
    align: "right",
    editable: true,
    valueFormatter: ({ value }) => value ? numberFormat(value) : "0.00"
  },
  {
    field: "WhtPrct",
    type: "number",
    headerName: pettyCashColNm.WhtPrct,
    width: 60,
    valueGetter: ({ value, id }) => id === 0 ? "" : `${value || 0}%`,
    align: "right",
    editable: true,
  },
  {
    field: "PayAmnt",
    headerName: pettyCashColNm.PayAmnt,
    width: 90,
    align: "right",
    valueFormatter: ({ value }) => value ? numberFormat(value) : "0.00"
  },
  {
    field: "actions",
    type: "actions",
    width: 25,
    minWidth: 0,
    getActions: (params) => params.id === 0 ? [] : [
      <GridActionsCellItem
        icon={<RemoveCircle />}
        label="Remove"
        onClick={() => handleRemoveRow(params.row)}
      />
    ]
  },
]

export const pettyCashForBalSelectColumns = [
  {
    field: 'ShowDetail',
    headerName: '',
    width: 40,
    align: 'center',
    renderCell: (params) => {
      return params.id === 0 ? null : (
        <IconButton size="small" >
          <ArticleRounded color="primary" />
        </IconButton>
      )
    }
  },
  {
    field: "No",
    headerName: "No.",
    width: 50,
    align: "center"
  },
  {
    field: "UsrNNm",
    headerName: pettyCashColNm.UsrNNm,
    width: 100,
  },
  {
    field: "PayTm",
    headerName: pettyCashColNm.PayTm,
    width: 110,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },
  {
    field: "ExpNm",
    headerName: pettyCashColNm.ExpNm,
    width: 180,
    valueGetter: ({ row, value }) => !value ? "-" : `${row.PrPCTrnsId ? row.InOutTyp === "I" ? "(รับเงิน)" : "(เงินเพิ่ม)" : ""}${row.ExpNm}`
  },
  {
    field: "Dscp",
    headerName: pettyCashColNm.Dscp,
    width: 120,
  },
  {
    field: "Loc",
    headerName: "สถานที่",
    valueGetter: ({ row }) => row.TakePlc || row.Loc || row.RtnPlc ? `${row.TakePlc}-${row.Loc}-${row.RtnPlc}` : "",
    width: 200,
  },
  {
    field: "AmntOut",
    headerName: "เงินจ่าย",
    width: 100,
    align: "right",
    type: "number",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "",
  },
  {
    field: "AmntIn",
    headerName: "เงินรับ",
    width: 100,
    align: "right",
    type: "number",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "",
  },
  {
    field: "AdmNNm",
    headerName: pettyCashColNm.AdmNNm,
    width: 100,
  },
]

export const pcPeriodColumns = [
  {
    field: 'ShowDetail',
    headerName: '',
    width: 40,
    align: 'center',
    renderCell: (params) => {
      return (
        <IconButton size="small" >
          <ArticleRounded color="primary" />
        </IconButton>
      )
    }
  },
  {
    field: "No",
    headerName: "No.",
    width: 50,
    align: 'center',
  },
  {
    field: "PCJnlNm",
    headerName: "บัญชี",
    width: 80,
  },
  {
    field: "AdmNNm",
    headerName: "ผู้ทำรายการ",
    width: 100,
  },
  {
    field: "PCPrdTm",
    headerName: "เวลาสรุปยอด",
    width: 140,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM/YYYY HH:mm") : "-";
    },
  },
  {
    field: "NumAll",
    headerName: "จำนวนรายการ",
    width: 90,
    align: 'center',
  },
  {
    field: "SumOut",
    headerName: "รวมยอดเงิน จ่าย",
    width: 110,
    valueFormatter: ({ value }) => value ? numberFormat(+value) : "0",
    align: 'right',
  },
  {
    field: "SumIn",
    headerName: "รวมยอดเงิน รับ",
    width: 110,
    valueFormatter: ({ value }) => value ? numberFormat(+value) : "0",
    align: 'right',
  },
  {
    field: "Bal",
    headerName: "ยอดคงเหลือ",
    width: 110,
    valueFormatter: ({ value }) => value ? numberFormat(+value) : "0",
    align: 'right',
  },
  {
    field: "Rmk",
    headerName: "หมายเหตุ",
    flex: 1,
    minWidth: 120,
  },
]

export const shorePeriodColumns = [
  {
    field: 'ShowDetail',
    headerName: '',
    width: 40,
    align: 'center',
    renderCell: (params) => {
      return params.id === 0 ? null : (
        <IconButton size="small" >
          <ArticleRounded color="primary" />
        </IconButton>
      )
    }
  },
  {
    field: "No",
    headerName: "No.",
    width: 50,
    align: "center"
  },
  {
    field: "PayTm",
    headerName: pettyCashColNm.PayTm,
    width: 110,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },
  {
    field: "UsrNNm",
    headerName: pettyCashColNm.UsrNNm,
    width: 90,
  },
  {
    field: "ExpNm",
    headerName: pettyCashColNm.ExpNm,
    flex: 1,
    valueGetter: ({ row, value }) => !value ? "-" : `${row.PrPCTrnsId ? row.InOutTyp === "I" ? "(รับเงิน)" : "(เงินเพิ่ม)" : ""}${row.ExpNm}`
  },
  {
    field: "JobOrdId",
    headerName: pettyCashColNm.JobOrdId,
    width: 70,
  },
  {
    field: "Loc",
    headerName: "สถานที่",
    valueGetter: ({ row }) => `${row.TakePlc || ""}-${row.Loc || ""}-${row.RtnPlc || ""}`,
    width: 150,
  },
  {
    field: "ContNo",
    headerName: "หมายเลขตู้",
    width: 120,
  },
  {
    field: "ContSizeFull",
    headerName: "ขนาดตู้",
    width: 60,
  },
  {
    field: "Amnt",
    headerName: pettyCashColNm.Amnt,
    width: 90,
    align: "right",
  },
  {
    field: "WhtPrct",
    headerName: pettyCashColNm.WhtPrct,
    width: 60,
    valueGetter: ({ value }) => `${value || 0}%`,
    align: "right",
  },
  {
    field: "OutAmnt",
    headerName: "เงินจ่าย",
    width: 100,
    align: "right",
    type: "number",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "",
  },
  {
    field: "InAmnt",
    headerName: "เงินรับ",
    width: 100,
    align: "right",
    type: "number",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "",
  },
  {
    field: "AdmNNm",
    headerName: pettyCashColNm.AdmNNm,
    width: 90,
  },
]

export const pcItemDataColumns = [
  // {
  //   field: 'ShowDetail',
  //   headerName: '',
  //   width: 40,
  //   align: 'center',
  //   renderCell: (params) => {
  //   return (
  //     <IconButton size="small" >
  //     <ArticleRounded color="primary"/>
  //     </IconButton>
  //   )}
  // },
  {
    field: "No",
    headerName: "No.",
    width: 50,
    align: 'center',
  },
  {
    field: "PCItmTm",
    headerName: pettyCashColNm.PCItmTm,
    width: 120,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },
  {
    field: "UsrNNm",
    headerName: pettyCashColNm.UsrNNm,
    width: 110,
  },
  {
    field: "ExpNm",
    headerName: expenseColNm.ExpNm,
    width: 160,
  },
  {
    field: "JobOrdId",
    headerName: jobOrderColNm.JobOrdId,
    width: 100,
  },
  {
    field: "JobNo",
    headerName: jobOrderColNm.JobNo,
    width: 100,
  },
  {
    field: "ContNo",
    headerName: jobOrderColNm.ContNo,
    width: 120,
  },
  {
    field: "ContSizeFull",
    headerName: jobOrderColNm.ContSizeFull,
    width: 70,
  },
  {
    field: "Loc",
    valueGetter: ({ row }) => `${row.TakePlc || ""}-${row.Loc || ""}-${row.RtnPlc || ""}`,
    headerName: jobOrderColNm.Loc,
    width: 180,
  },
  {
    field: "Amnt",
    headerName: pettyCashColNm.Amnt,
    width: 100,
    type: "number",
    align: "right",
    valueFormatter: ({ value }) => value ? numberFormat(value) : "-",
  },
  {
    field: "WhtPrct",
    headerName: pettyCashColNm.WhtPrct,
    width: 70,
    type: "number",
    align: "right",
    valueFormatter: ({ id, value }) => id === 0 ? null : value ? `${numberFormat(value)}%` : "0%",
  },
  {
    field: "PayAmnt",
    headerName: pettyCashColNm.PayAmnt,
    width: 100,
    type: "number",
    align: "right",
    valueFormatter: ({ value }) => value ? numberFormat(value) : "-",
  },
  {
    field: "Dscp",
    headerName: pettyCashColNm.Dscp,
    flex: 1,
    minWidth: 120,
  },
]