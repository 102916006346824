import { ArticleRounded } from "@mui/icons-material"
import { Button, IconButton } from "@mui/material"
import dayjs from "dayjs"
import { numberFormat } from "utils"

export const driverTripPaymentColumns = [
  {
    field: "No",
    headerName: "No.",
    width: 50,
    align: 'center',
  },
  {
    field: 'ShowDetail',
    headerName: '',
    width: 60,
    align: 'center',
    renderCell: (params) => {
      return (
        <IconButton size="small" >
          <ArticleRounded color="primary" />
        </IconButton>
      )
    }
  },
  // {
  //   field: 'IsCmpt',
  //   headerName: '',
  //   width: 40,
  //   align: 'center',
  //   renderCell: (params) => {
  //     return params.row.IsCmpt ? <Typography color="success">✔</Typography> : ""
  //   }
  // },
  // {
  //   field: 'Print',
  //   headerName: '',
  //   width: 40,
  //   align: 'center',
  //   renderCell: (params) => {
  //     return (
  //       <IconButton size="small">
  //         <PrintRounded color="primary" />
  //       </IconButton>
  //     )
  //   }
  // },
  {
    field: "JobOrdId",
    headerName: "เลขที่ใบงาน",
    width: 90,
  },
  {
    field: "AptTm",
    headerName: "วันที่นัด",
    width: 100,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },
  {
    field: "FinDte",
    headerName: "วันที่ลงจบ",
    width: 100,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },
  {
    field: "Loc",
    headerName: "สถานที่",
    valueGetter: ({row: {TakePlc, Loc, RtnPlc}}) => `${TakePlc}-${Loc}-${RtnPlc}`,
    width: 250,
  },
  {
    field: "Sts1NNm",
    headerName: "พนักงานรับ",
    width: 100,
  },
  {
    field: "Sts3NNm",
    headerName: "พนักงานส่ง",
    width: 100,
  },
  {
    field: "Sts4NNm",
    headerName: "พนักงานพ้ก",
    width: 100,
  },
  {
    field: "Sts5NNm",
    headerName: "พนักงานคืน",
    width: 100,
  },
  {
    field: "AccNm",
    headerName: "ผุ้รับเงิน",
    valueGetter: ({row: {AccNm, DrvNm}}) => AccNm || DrvNm,
    width: 150,
  },
  
]

export const driverTripPaymentEndColumns = [
  {
    field: "OthAmnt",
    headerName: "ค่าอื่นๆ",
    width: 100,
    align: 'right',
    valueFormatter: ({ value }) => value ? numberFormat(value) : '-'
  },
  {
    field: "OthDscp",
    headerName: "รายละเอียดค่าอื่นๆ",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "SalDte",
    headerName: "คิดเงินดือน",
    width: 100,
    // valueFormatter: ({ value }) => value ? dayjs(value).format("MM/YYYY") : '',
    renderCell: ({ value }) => (
      <Button size="small" variant={value ? "outlined" : "contained"}>
        {value ? dayjs(value).format("DD/MM/YYYY") : "กำหนดเดือน"}
      </Button>
    )
  },
]